import emptySplitApi from "../utils/rtk";
import { END_POINTS } from "../constants/url";
import { Banner, CommonBody } from "../types/General";
import { User } from "../types/User";

type CommonResponseType = {
    statusCode: number;
    message: string;
};



export const BannerApi = emptySplitApi.injectEndpoints({
    endpoints: (builder) => ({
        postBanner: builder.mutation<
            CommonResponseType & { data: Banner },
            CommonBody
        >({
            query: (body) => ({
                url: END_POINTS.banner,
                method: "POST",
                body,
            }),
        }),

        getAllbanner: builder.query<
            CommonResponseType & { data: any },
            { page: number, size: number, search: string }
        >({
            query: ({ search, page, size }) => ({
                url: `${END_POINTS.banner}?search=${search}&page=${page}&size=${size}`,
                method: "GET"
            })
        }),

        getBannerById: builder.query<
            CommonResponseType & { data: Banner },
            { id: string | undefined }
        >({
            query: ({ id }) => ({
                url: `${END_POINTS.banner}/${id}`,
                method: "GET",
            })
        }),

        updateBannerById: builder.mutation<
            CommonResponseType & { data: Banner },
            { id: string | undefined, body: CommonBody }
        >({
            query: ({ body, id }) => ({
                url: `${END_POINTS.banner}/${id}`,
                method: "PUT",
                body
            })
        }),

        deleteBanner: builder.mutation<
            CommonResponseType & { data: Banner },
            { id: string | undefined }
        >({
            query: ({ id }) => ({
                url: `${END_POINTS.banner}/${id}`,
                method: "DELETE"
            })
        }),


    })
})

export const {
    usePostBannerMutation,
    useLazyGetAllbannerQuery,
    useLazyGetBannerByIdQuery,
    useUpdateBannerByIdMutation,
    useDeleteBannerMutation
} = BannerApi;