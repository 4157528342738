import emptySplitApi from "../utils/rtk";
import { END_POINTS } from "../constants/url";
import { CommonBody, UserAdded } from "../types/General";



type CommonResponseType = {
    statusCode: number;
    message: string;
};


export const subAdminApi = emptySplitApi.injectEndpoints({
    endpoints: (builder) => ({
        PostSubAdmin: builder.mutation<
            CommonResponseType & { data: any },
            CommonBody
        >({
            query: (body) => ({
                url: END_POINTS.subAdmin,
                method: "POST",
                body,
            }),
        }),

        getAllSubAdmin: builder.query<
            CommonResponseType & { data: any },
            { page: number, size: number, search: string }
        >({
            query: ({ search, page, size }) => ({
                url: `${END_POINTS.subAdmin}?search=${search}&page=${page}&size=${size}`,
                method: "GET"
            })
        }),

        getSubAdminById: builder.query<
            CommonResponseType & { data: any },
            { id: string | undefined }
        >({
            query: ({ id }) => ({
                url: `${END_POINTS.subAdmin}/${id}`,
                method: "GET",
            })
        }),

        UpdateSubAdmin: builder.mutation<
            CommonResponseType & { data: any },
            { id: string | undefined, body: CommonBody }
        >({
            query: ({ body, id }) => ({
                url: `${END_POINTS.subAdmin}/${id}`,
                method: "PUT",
                body
            })
        }),

        deleteSubAdmin: builder.mutation<
            CommonResponseType & { data: any },
            { id: string | undefined }
        >({
            query: ({ id }) => ({
                url: `${END_POINTS.subAdmin}/${id}`,
                method: "DELETE"
            })
        }),

        getSubAdminExports: builder.query<CommonResponseType & { data: string }, {
        }>({
            query: () => ({
                url: `${END_POINTS.subAdminsExports}`,
                method: "GET",
            }),
        }),

        // roles

        getAllRolesWithoutPagination: builder.query<
            CommonResponseType & { data: any },
            {}
        >({
            query: () => ({
                url: `${END_POINTS.role}`,
                method: "GET",
            }),
        }),

        postRole: builder.mutation<
            CommonResponseType & { data: any },
            CommonBody
        >({
            query: (body) => ({
                url: END_POINTS.role,
                method: "POST",
                body,
            }),
        }),

        GetAllRoles: builder.query<
            CommonResponseType & { data: any },
            { page: number, size: number, search: string }
        >({
            query: ({ search, page, size }) => ({
                url: `${END_POINTS.role}?search=${search}&page=${page}&size=${size}`,
                method: "GET"
            })
        }),

        getRoleById: builder.query<
            CommonResponseType & { data: any },
            { id: string | undefined }
        >({
            query: ({ id }) => ({
                url: `${END_POINTS.role}/${id}`,
                method: "GET",
            })
        }),

        UpdateRole: builder.mutation<
            CommonResponseType & { data: any },
            { id: string | undefined, body: CommonBody }
        >({
            query: ({ id,body}) => ({
                url: `${END_POINTS.role}/${id}`,
                method: "PUT",
                body
            })
        }),

        deleteRole: builder.mutation<
            CommonResponseType & { data: any },
            { id: string | undefined }
        >({
            query: ({ id }) => ({
                url: `${END_POINTS.role}/${id}`,
                method: "DELETE"
            })
        }),


    })
})

export const {
    usePostSubAdminMutation,
    useLazyGetAllSubAdminQuery,
    useLazyGetSubAdminByIdQuery,
    useUpdateSubAdminMutation,
    useDeleteSubAdminMutation,
    useLazyGetSubAdminExportsQuery,

    //roles
    useLazyGetAllRolesWithoutPaginationQuery,
    useLazyGetAllRolesQuery,
    useLazyGetRoleByIdQuery,
    useUpdateRoleMutation,
    useDeleteRoleMutation,
    usePostRoleMutation

} = subAdminApi;