import emptySplitApi from "../utils/rtk";
import { END_POINTS } from "../constants/url";
import { CommonBody } from "../types/General";
import { User } from "../types/User";

type CommonResponseType = {
    statusCode: number;
    message: string;
};

type PostVerifyOtpResponse = {
    statusCode: number;
    message: string;
    data: User;
};
type PostChangePasswordResponse = {
    statusCode: number;
    message: string;
    data: any;
};
type PostChangePasswordBody = {
    oldPassword: string;
    password: string;
};
type PostSetPasswordBody = {
    password: string;
};
type PostSetPasswordResponse = {
    statusCode: number;
    message: string;
    data: any;
};
type PostVerifyOtpBody = {
    key: string;
    code: string;
};


export const authApi = emptySplitApi.injectEndpoints({
    endpoints: (builder) => ({
        postLogin: builder.mutation<
            CommonResponseType & { data: User },
            CommonBody
        >({
            query: (body) => ({
                url: END_POINTS.login,
                method: "POST",
                body,
            }),
        }),

        postForgotPassword: builder.mutation<
            CommonResponseType,
            CommonBody
        >({
            query: (body) => ({
                url: END_POINTS.forgotPassword,
                method: "POST",
                body,
            }),
        }),

        postVerifyOtp: builder.mutation<PostVerifyOtpResponse, CommonBody>({
            query: (body) => ({
                url: END_POINTS.verifyOtp,
                method: "POST",
                body,
            }),
        }),

        postSetPassword: builder.mutation<
            PostSetPasswordResponse,
            CommonBody
        >({
            query: (body) => ({
                url: END_POINTS.setPassword,
                method: "POST",
                body,
            }),
        }),

        postChangePassword: builder.mutation<
            PostChangePasswordResponse,
            CommonBody
        >({
            query: (body) => ({
                url: END_POINTS.changePassword,
                method: "POST",
                body,
            }),
        }),

        getUser: builder.query<CommonResponseType & { data: User }, {}>({
            query: () => ({
                url: END_POINTS.getProfile,
                method: "GET",
            }),
        }),

        editProfile: builder.mutation<
            { statusCode: number; data: User; message: string },
            // Partial<Omit<User, "image">>
            CommonBody
        >({
            query: (body) => ({
                url: END_POINTS.updateProfile,
                method: "PUT",
                body,
            }),
        }),

        logout: builder.query<CommonResponseType & { data: User }, {}>({
            query: () => ({
                url: END_POINTS.logout,
                method: "GET",
            }),
        }),

    })
})

export const {
    usePostLoginMutation,
    usePostForgotPasswordMutation,
    usePostVerifyOtpMutation,
    usePostSetPasswordMutation,
    usePostChangePasswordMutation,
    useLazyGetUserQuery,
    useEditProfileMutation,
    useLazyLogoutQuery
} = authApi;