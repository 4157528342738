import emptySplitApi from "../utils/rtk";
import { END_POINTS } from "../constants/url";

type CommonResponseType = {
    statusCode: number;
    message: string;
};



export const dashboardApi = emptySplitApi.injectEndpoints({
    endpoints: (builder) => ({
        getDashboard: builder.query<
            CommonResponseType & { data: any },
            {}
        >({
            query: () => ({
                url: `${END_POINTS.dashboard}`,
                method: "GET"
            })
        }),

        getUserGraph: builder.query<
            CommonResponseType & { data: any },
            { type: string }
        >({
            query: ({ type }) => ({
                url: `${END_POINTS.newUsersGraph}?type=${type}`,
                method: "GET",
            }),
        }),

        getTotalUserGraph: builder.query<
            CommonResponseType & { data: any },
            { type: string }
        >({
            query: ({ type }) => ({
                url: `${END_POINTS.totalUserGraph}?type=${type}`,
                method: "GET",
            }),
        }),


    })
})

export const {
    useLazyGetDashboardQuery,
    useLazyGetUserGraphQuery,
    useLazyGetTotalUserGraphQuery
} = dashboardApi;