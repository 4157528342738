import { PropsWithoutRef, useEffect, useState } from "react";
import {
  Drawer,
  Toolbar,
  List,
  ListItemIcon,
  ListItemText,
  ListItemButton,
  Box,
  IconButton,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import SummarizeTwoToneIcon from "@mui/icons-material/SummarizeTwoTone";
import CloseIcon from "@mui/icons-material/Close";
import DashboardIcon from "@mui/icons-material/Dashboard";
import PeopleIcon from "@mui/icons-material/People";
import BarChartIcon from "@mui/icons-material/BarChart";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import ListAltIcon from "@mui/icons-material/ListAlt";
import SettingsIcon from "@mui/icons-material/Settings";
import ViewCarouselIcon from "@mui/icons-material/ViewCarousel";
import SubscriptionsIcon from "@mui/icons-material/Subscriptions";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import EmailIcon from "@mui/icons-material/Email";
import { SIDEBAR_WIDTH } from "../constants";
import useAuth from "../hooks/useAuth";

interface Props {
  mobileOpen: boolean;
  handleSidebarToggle: () => void;
}

export default function Sidebar({
  mobileOpen,
  handleSidebarToggle,
}: PropsWithoutRef<Props>) {
  const UserData = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  //   const getPermission=()=>{
  // if(userData?.role==1)
  //   }
  const userPermissions =
    UserData?.role !== 1
      ? UserData?.permissions?.[0]?.permission
      : UserData?.permissions?.[0]?.length
      ? UserData?.permissions?.[0]?.permission
      : [];

  const items = [
    {
      label: "Dashboard",
      icon: <DashboardIcon />,
      key: "1",
      selected: "/dashboard",
    },
    {
      label: "Manage Users",
      icon: <PeopleIcon />,
      key: "2",
      selected: "/manageusers",
    },
    {
      label: "Manage Themes",
      icon: <SubscriptionsIcon />,
      key: "3",
      selected: "/manage-themes",
    },
    {
      label: "Manage Ad's",
      icon: <ViewCarouselIcon />,
      key: "4",
      selected: "/manage-banner",
    },
    {
      label: "Notification Management",
      icon: <NotificationsActiveIcon />,
      key: "5",
      selected: "/manage-notifications",
    },
    {
      label: "Manage Sub-Admin",
      icon: <PersonAddIcon />,
      key: "6",
      selected: "/manage-subAdmin",
    },
    {
      label: "Manage Cms",
      icon: <SummarizeTwoToneIcon />,
      key: "7",
      selected: "/cms",
    },
  ];

  const [list, setList] = useState<any>(
    UserData?.role === 1 ? items : userPermissions
  );

  useEffect(() => {
    let arr = [];
    if (UserData?.role === 1) {
      setList(items);
    } else if (userPermissions?.length) {
      const permission = userPermissions;
      arr = items.map((item) => {
        let idx = -1;
        idx = permission?.findIndex((ele: any) => ele?.label === item?.label);
        if (idx > -1) {
          if (permission[idx].isView) {
            return item;
          }
          return false;
        }
      });
      setList(arr);
    }
  }, [UserData]);

  const handleNavigation = () => {
    if (UserData?.role === 2) {
      const permissions = UserData?.permissions?.[0]?.permission;
      if (UserData?.role === 2 && permissions?.length > 0) {
        const firstPermissionLabel = permissions[0]?.label;
        const redirectPath = items.find(
          (item) => item.label === firstPermissionLabel
        )?.selected;

        if (redirectPath) {
          navigate(redirectPath);
        } else {
          console.error("Invalid permission label for redirection");
          // Handle the case where the label doesn't match any item in the array
        }
      }
    } else {
      // Default redirect for other roles or when no permissions are available
      navigate("/dashboard");
    }
  };

  const drawer = (
    <>
      <Toolbar className="sidebar-logo" sx={{ position: "relative" }}>
        <figure>
          <img
            src="/static/images/logo.png"
            alt=""
            onClick={() => navigate("/dashboard")}
          />
        </figure>
        <IconButton
          sx={{
            position: "absolute",
            top: "10px",
            right: "10px",
            color: "white",
            display: { lg: "none" },
          }}
          onClick={handleSidebarToggle}
        >
          <CloseIcon />
        </IconButton>
      </Toolbar>

      <List sx={{ flexGrow: 1 }} className="sidebr-lst">
        <ListItemButton
          className="lst-itm"
          selected={location.pathname === "/dashboard"}
          onClick={() => navigate("/dashboard")}
        >
          <ListItemIcon>
            <DashboardIcon />
          </ListItemIcon>
          <ListItemText className="lstitm-txt" primary="Dashboard" />
        </ListItemButton>

        <ListItemButton
          className="lst-itm"
          selected={location.pathname === "/manageusers"}
          onClick={() => navigate("/manageusers")}
        >
          <ListItemIcon>
            <PeopleIcon />
          </ListItemIcon>
          <ListItemText className="lstitm-txt" primary="Manage Users" />
        </ListItemButton>

        <ListItemButton
          className="lst-itm"
          selected={location.pathname === "/manage-themes"}
          onClick={() => navigate("/manage-themes")}
        >
          <ListItemIcon>
            <SubscriptionsIcon />
          </ListItemIcon>
          <ListItemText className="lstitm-txt" primary="Manage Themes" />
        </ListItemButton>

        <ListItemButton
          className="lst-itm"
          selected={location.pathname === "/manage-banner"}
          onClick={() => navigate("/manage-banner")}
        >
          <ListItemIcon>
            <ViewCarouselIcon />
          </ListItemIcon>
          <ListItemText className="lstitm-txt" primary="Manage Ads" />
        </ListItemButton>

        <ListItemButton
          className="lst-itm"
          selected={location.pathname === "/manage-notifications"}
          onClick={() => navigate("/manage-notifications")}
        >
          <ListItemIcon>
            <NotificationsActiveIcon />
          </ListItemIcon>
          <ListItemText
            className="lstitm-txt"
            primary="Notifications Management"
          />
        </ListItemButton>

        <ListItemButton
          className="lst-itm"
          selected={location.pathname === "/manage-subAdmin"}
          onClick={() => navigate("/manage-subAdmin")}
        >
          <ListItemIcon>
            <PersonAddIcon />
          </ListItemIcon>
          <ListItemText className="lstitm-txt" primary="Manage Sub-Admin" />
        </ListItemButton>

        <ListItemButton
          className="lst-itm"
          selected={location.pathname === "/cms"}
          onClick={() => navigate("/cms")}
        >
          <ListItemIcon>
            <SummarizeTwoToneIcon />
          </ListItemIcon>
          <ListItemText className="lstitm-txt" primary="Manage CMS" />
        </ListItemButton>
      </List>
    </>
  );

  return (
    <Box
      component="nav"
      sx={{ flexShrink: { lg: 0 } }}
      className="sidebar-main-div"
    >
      <Drawer
        className="sidebar_drawer"
        variant="temporary"
        open={mobileOpen}
        onClose={handleSidebarToggle}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          display: { xs: "block", lg: "none" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: SIDEBAR_WIDTH,
          },
        }}
      >
        <Toolbar className="sidebar-logo" sx={{ position: "relative" }}>
          <figure>
            <img
              src="/static/images/logo.png"
              alt=""
              // onClick={() => navigate("/dashboard")}
              onClick={handleNavigation}
            />
          </figure>
          <IconButton
            sx={{
              position: "absolute",
              top: "10px",
              right: "10px",
              color: "white",
              display: { lg: "none" },
            }}
            onClick={handleSidebarToggle}
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
        {list?.length
          ? list
              .filter((ele: any) => ele?.label !== "")
              .map((item: any) => {
                const isSelected = location.pathname.includes(item?.selected);
                return (
                  <List
                    key={item?.label}
                    className="sidebr-lst"
                    sx={{ padding: 0 }}
                  >
                    <ListItemButton
                      className={`lst-itm ${isSelected ? "Mui-selected" : ""}`}
                      onClick={() => navigate(item?.selected)}
                    >
                      <ListItemIcon>{item?.icon}</ListItemIcon>
                      <ListItemText
                        className="lstitm-txt"
                        primary={item?.label}
                      />
                    </ListItemButton>
                  </List>
                );
              })
          : null}
      </Drawer>
      <Drawer
        variant="permanent"
        className="sidebar_drawer"
        sx={{
          display: { xs: "none", lg: "block" },
          "& .MuiDrawer-paper": {
            width: SIDEBAR_WIDTH,
            boxSizing: "border-box",
          },
        }}
        open
      >
        <Toolbar className="sidebar-logo" sx={{ position: "relative" }}>
          <figure>
            <img
              src="/static/images/logo.png"
              alt=""
              // onClick={() => navigate("/dashboard")}
              onClick={handleNavigation}
            />
          </figure>
          <IconButton
            sx={{
              position: "absolute",
              top: "10px",
              right: "10px",
              color: "white",
              display: { lg: "none" },
            }}
            onClick={handleSidebarToggle}
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
        {list?.length
          ? list
              // .filter((ele: any) => ele !== undefined)
              .map((item: any) => {
                const isSelected = location.pathname.includes(item?.selected);
                console.log("MOHIT", item?.label, isSelected);

                return (
                  item?.label && (
                    <List
                      key={item?.label}
                      className="sidebr-lst"
                      sx={{ padding: 0 }}
                    >
                      <ListItemButton
                        className={`lst-itm ${
                          isSelected ? "Mui-selected" : ""
                        }`}
                        onClick={() => navigate(item?.selected)}
                      >
                        <ListItemIcon>{item?.icon}</ListItemIcon>
                        <ListItemText
                          className="lstitm-txt"
                          primary={item?.label}
                        />
                      </ListItemButton>
                    </List>
                  )
                );
              })
          : null}
      </Drawer>
    </Box>
  );
}
