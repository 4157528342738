import MainContainer from "../../layout/MainContainer";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  Input,
  TextField,
  Typography,
} from "@mui/material";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { ChangeEvent, useEffect, useState } from "react";
import CancelIcon from "@mui/icons-material/Cancel";
import AddIcon from "@mui/icons-material/Add";
import moment from "moment";
import {
  useLazyGetBannerByIdQuery,
  usePostBannerMutation,
  useUpdateBannerByIdMutation,
} from "../../services/banner";
import { Banner, CommonBody } from "../../types/General";
import { showError, showToast } from "../../constants/toast";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import { UploadMedia } from "../../utils/mediaUpload";
import { Pagination } from "../../components";

const AddBanner = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  const [addBanner, { isLoading }] = usePostBannerMutation();
  const [getBannerById] = useLazyGetBannerByIdQuery();
  const [updateBanner] = useUpdateBannerByIdMutation();

  const [image, setImage] = useState<string>("");
  const [banner, setBanner] = useState<Banner>();
  console.log(banner, "banner banner");

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      title: banner?.title || "",
      message: banner?.message || "",
      website: banner?.website || "",
      startTime: banner?.startTime
        ? new Date(banner.startTime).toISOString().slice(0, 16)
        : "",
      endTime: banner?.endTime
        ? new Date(banner.endTime).toISOString().slice(0, 16)
        : "",
    },
    validationSchema: Yup.object({
      title: Yup.string()
        .required("This field is required")
        .max(80, "Maximum 80 character are allowed")
        .min(3, "Minimum 3 characters are required"),
      message: Yup.string()
        .required("This field is required")
        .max(80, "Maximum 80 character are allowed")
        .min(3, "Minimum 3 characters are required"),
      website: Yup.string()
        .required("This field is required")
        .max(80, "Maximum 80 character are allowed")
        .min(3, "Minimum 3 characters are required"),
      startTime: Yup.string()
        .required("Starting time is required")
        .test(
          "is-valid-start-time",
          "Start time must be in the future",
          function (value) {
            const now = new Date();
            const startTime = new Date(value);
            return startTime > now;
          }
        ),

      endTime: Yup.string()
        .required("Ending time is required")
        .test(
          "is-valid-end-time",
          "End time must be after start time",
          function (value) {
            const startTime: any = new Date(formik.values.startTime);
            const endTime = new Date(value);
            return endTime > startTime;
          }
        ),
    }),

    onSubmit: async () => {
      formik.setSubmitting(true);

      const body = {
        title: formik.values.title,
        bannerImage: image,
        message: formik.values.message,
        website: formik.values.website,
        startTime: formik.values.startTime,
        endTime: formik.values.endTime,
      };
      console.log(body, "body for banner");

      const Body: {
        title?: string;
        bannerImage?: string;
        message?: string;
        website?: string;
        startTime?: string;
        endTime?: string;
      } = {};

      if (formik.values.title !== banner?.title) {
        Body["title"] = formik.values.title;
      }
      if (formik.values.message !== banner?.message) {
        Body["message"] = formik.values.message;
      }
      if (formik.values.website !== banner?.website) {
        Body["website"] = formik.values.website;
      }
      if (image !== banner?.bannerImage) {
        Body["bannerImage"] = image;
      }
      // if (new Date(formik.values.startTime).toISOString() !== banner?.startTime) {
      //   Body['startTime'] = formik.values.startTime;
      // }
      // if (new Date(formik.values.endTime).toISOString() !== banner?.endTime) {
      //   Body['endTime'] = formik.values.endTime;
      // }

      if (!image) {
        showError("Image is required");
        return;
      }

      if (id) {
        try {
          let encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
          const response = await updateBanner({
            id: id,
            body: encryptedBody,
          }).unwrap();
          if (response?.statusCode === 200) {
            showToast(response?.message || "");
            navigate("/manage-banner");
          } else {
            showError(response?.message || "");
          }
        } catch (error: any) {
          showError(error?.data?.message || "");
          console.log(error);
        }
      } else {
        try {
          let encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
          const response = await addBanner(encryptedBody).unwrap();
          if (response?.statusCode === 200) {
            showToast("Banner Created Successfully");
            formik.resetForm();
            navigate("/manage-banner");
          } else {
            showError(response?.message || "");
          }
        } catch (error: any) {
          showError(error?.data?.message || "");
          console.log(error);
        }
      }
    },
  });

  // const handleImageUpload = async (
  //   event: React.ChangeEvent<HTMLInputElement>
  // ) => {
  //   const files = event.target;
  //   const file = files?.files?.length ? files?.files[0] : "";
  //   const res = await UploadMedia(file);
  //   if (res?.statusCode === 200) {
  //     setImage(res?.data);
  //   } else {
  //     showError(res?.message);
  //   }
  // };

  const handleImageUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files = event.target;
    const file = files?.files?.length ? files?.files[0] : "";
    if (file) {
      if (file.type.startsWith("image/")) {
        console.log(file, "FILE");
        // setImage(URL.createObjectURL(file));
        setImage(URL.createObjectURL(file));
        const res = await UploadMedia(file);
        if (res?.statusCode === 200) {
          setImage(res?.data);
          showToast("Image uploaded successfully");
        } else {
          showError(res?.message);
        }
      } else {
        setImage(URL.createObjectURL(file));
        const res = await UploadMedia(file);
        if (res?.statusCode === 200) {
          setImage(res?.data);
          showToast("Video uploaded successfully");
        } else {
          showError(res?.message);
        }
      }
    }
  };

  const getBannerDetail = async (id: string) => {
    try {
      const response = await getBannerById({ id }).unwrap();
      if (response?.statusCode === 200) {
        setBanner(response?.data);
        setImage(response?.data?.bannerImage);
      }
    } catch (error: any) {
      console.log(error, "errror");
      showError(error?.data?.message || "");
    }
  };

  useEffect(() => {
    if (id) {
      getBannerDetail(id);
    }
  }, []);

  return (
    <div>
      <>
        {/* <Loader isLoad={isLoading || updateBannerData?.isLoading} /> */}
        <MainContainer>
          <div className="main_loyout">
            <div className="dashboard">
              {state?.type === "edit" ? (
                <h1 className="mn_hdng">Edit Banner</h1>
              ) : (
                <h1 className="mn_hdng">Add Banner</h1>
              )}
              <Button
                className="btn btn_primary"
                onClick={() => {
                  navigate("/manage-banner");
                }}
              >
                Back
              </Button>
            </div>
            <Card className="cards">
              <form onSubmit={formik.handleSubmit}>
                <CardContent sx={{ p: 1 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography className="custom_label">
                        Upload Banner
                      </Typography>
                      {image ? (
                        <div className="upload_image_preview2">
                          {image.includes("mp4") ? (
                            <video
                              autoPlay
                              muted
                              controls
                              style={{ width: "100%", height: "100%" }}
                            >
                              <source src={image} type="video/mp4" />
                              Your browser does not support the video tag.
                            </video>
                          ) : (
                            <CardMedia
                              component="img"
                              image={image}
                              alt="photo"
                            />
                          )}

                          <CancelIcon
                            onClick={() => {
                              setImage("");
                            }}
                          />
                        </div>
                      ) : (
                        <Box className="upload_image_bnr">
                          <label htmlFor="icon-button-file">
                            <Input
                              sx={{ display: "none" }}
                              id="icon-button-file"
                              type="file"
                              inputProps={{
                                accept:
                                  "image/png,image/jpeg,video/mp4,video/webm,video/avi,image/gif",
                              }}
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                              ) => handleImageUpload(e)}
                            />
                            <Button
                              component="span"
                              className="upload_image_btn"
                            >
                              <img
                                src={
                                  image ? image : "/static/images/download.png"
                                }
                                alt=""
                              />
                              <AddIcon />
                            </Button>
                          </label>
                        </Box>
                      )}
                    </Grid>
                    <Grid item xs={5}>
                      <Typography className="custom_label">
                        Advertisement title
                      </Typography>
                      <TextField
                        hiddenLabel
                        type={"text"}
                        name="title"
                        variant="outlined"
                        fullWidth
                        placeholder="Advertisement title"
                        value={formik.values.title}
                        onChange={(val) => {
                          formik.handleChange(val);
                        }}
                        className="text_field"
                        onBlur={formik.handleBlur}
                        helperText={formik.touched.title && formik.errors.title}
                        inputProps={{ maxLength: 30 }}
                      />
                    </Grid>

                    <Grid item xs={5}>
                      <Typography className="custom_label">
                        Website Link
                      </Typography>

                      <TextField
                        hiddenLabel
                        type={"text"}
                        name="website"
                        variant="outlined"
                        fullWidth
                        placeholder="Website Link"
                        value={formik.values.website}
                        onChange={(val) => {
                          formik.handleChange(val);
                        }}
                        className="text_field"
                        onBlur={formik.handleBlur}
                        helperText={
                          formik.touched.website && formik.errors.website
                        }
                        inputProps={{ maxLength: 30 }}
                      />
                    </Grid>

                    <Grid item xs={5}>
                      <Typography className="custom_label">
                        {" "}
                        Start time
                      </Typography>
                      <TextField
                        hiddenLabel
                        type={"datetime-local"}
                        name="startTime"
                        variant="outlined"
                        fullWidth
                        placeholder="Start Time"
                        className="text_field"
                        inputProps={{ maxLength: 30 }}
                        onBlur={formik.handleBlur}
                        value={formik.values.startTime}
                        onChange={(val) => {
                          formik.handleChange(val);
                        }}
                        helperText={
                          formik.touched.startTime && formik.errors.startTime
                        }
                      />
                    </Grid>
                    <Grid item xs={5}>
                      <Typography className="custom_label">
                        {" "}
                        End time
                      </Typography>
                      <TextField
                        hiddenLabel
                        type={"datetime-local"}
                        name="endTime"
                        variant="outlined"
                        fullWidth
                        placeholder="End Time"
                        className="text_field"
                        inputProps={{ maxLength: 30 }}
                        onBlur={formik.handleBlur}
                        value={formik.values.endTime}
                        onChange={(val) => {
                          formik.handleChange(val);
                        }}
                        helperText={
                          formik.touched.endTime && formik.errors.endTime
                        }
                      />
                    </Grid>

                    <Grid item xs={5}>
                      <Typography className="custom_label">
                        Promotional Message
                      </Typography>
                      <TextField
                        multiline
                        minRows={4}
                        hiddenLabel
                        type={"text"}
                        className="text_field"
                        name="message"
                        variant="outlined"
                        fullWidth
                        placeholder="Promotional Message"
                        value={formik.values.message}
                        onChange={(val) => {
                          formik.handleChange(val);
                        }}
                        onBlur={formik.handleBlur}
                        helperText={
                          formik.touched.message && formik.errors.message
                        }
                        inputProps={{ maxLength: 30 }}
                      />
                    </Grid>

                    <br />
                  </Grid>

                  <div className="form_btn">
                    <Button
                      size="large"
                      type="submit"
                      className="btn btn_primary"
                      // onClick={() => setError(true)}
                    >
                      Save
                    </Button>
                  </div>
                </CardContent>
              </form>
            </Card>
          </div>
        </MainContainer>
      </>
    </div>
  );
};

export default AddBanner;
