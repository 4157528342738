import LoginPage from "./auth/login";
import DashBoardPage from "./dashBoard";
import ManageUsers from "./users";
import ForgotPasswordPage from "./auth/forgotPassword";
import VerifyOtp from "./auth/otp";
import Analytics from "./analytics";
import Faq from "./faq";
import Settings from "./settings";
import ResetPassword from "./auth/resetPassword";
import Profile from "./profile";
import ManageCms from "./manageCms";
import ManageBanner from "./manageBanner";
import ManageNotifications from "./manageNotification";
import AddNotification from "./manageNotification/add";
import CustomerSupport from "./customerSupport";
import ManageSubAdmin from "./manageSubAdmin";
import AddSubAdmin from "./manageSubAdmin/add";
import ManageCategories from "./manageIcons";
import AddCategories from "./manageIcons/add";
import RecievedNotifications from "./manageNotification/receivedNotification";
import AddBanner from "./manageBanner/add";
import ManageSubscription from "./manageThemes";
import AddSubscriptionPlan from "./manageThemes/add";
import SubCategories from "./manageIcons/details";
import ChangePassword from "./auth/changePassword";
import AddIcons from "./manageIcons/add";
import AddTheme from "./manageThemes/add";
import ManageThemes from "./manageThemes";
import ManageIcons from "./manageIcons";


// eslint-disable-next-line import/no-anonymous-default-export
export default {
  LoginPage,
  DashBoardPage,
  AddTheme,
  ManageUsers,
  ManageIcons,
  ManageThemes,
  ForgotPasswordPage,
  AddIcons,
  VerifyOtp,
  Analytics,
  Faq,
  ResetPassword,
  Profile,
  ManageCms,
  ManageBanner,
  Settings,
  ManageNotifications,
  AddNotification,
  CustomerSupport,
  ManageSubAdmin,
  AddSubAdmin,
  ManageCategories,
  AddCategories,
  RecievedNotifications,
  AddBanner,
  ManageSubscription,
  AddSubscriptionPlan,
  SubCategories,
  ChangePassword,
 
};
