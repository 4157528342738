import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyCDqHw2PFzgY47fagnrFD6YIsm5LCR5jAo",
  authDomain: "gold-valley-cf4cc.firebaseapp.com",
  databaseURL: "https://gold-valley-cf4cc.firebaseio.com",
  projectId: "gold-valley-cf4cc",
  storageBucket: "gold-valley-cf4cc.appspot.com",
  messagingSenderId: "1006010084073",
  appId: "1:1006010084073:web:c48249a4c139cbcbf45e78",
  measurementId: "G-14RRWKM2YB"
};
const app = initializeApp(firebaseConfig);
export default app;
