import emptySplitApi from "../utils/rtk";
import { END_POINTS } from "../constants/url";
import { CommonBody, UserAdded } from "../types/General";



type CommonResponseType = {
    statusCode: number;
    message: string;
};



export const ThemeApi = emptySplitApi.injectEndpoints({
    endpoints: (builder) => ({
        postTheme: builder.mutation<
            CommonResponseType & { data: any },
            CommonBody
        >({
            query: (body) => ({
                url: END_POINTS.theme,
                method: "POST",
                body,
            }),
        }),

        getAllTheme: builder.query<
            CommonResponseType & { data: any },
            { page: number, size: number, search: string }
        >({
            query: ({ search, page, size }) => ({
                url: `${END_POINTS.theme}?search=${search}&page=${page}&size=${size}`,
                method: "GET"
            })
        }),

        getThemeById: builder.query<
            CommonResponseType & { data: any },
            { id: string | undefined }
        >({
            query: ({ id }) => ({
                url: `${END_POINTS.theme}/${id}`,
                method: "GET",
            })
        }),

        updateThemeById: builder.mutation<
            CommonResponseType & { data: any },
            { id: string | undefined, body: CommonBody }
        >({
            query: ({ body, id }) => ({
                url: `${END_POINTS.theme}/${id}`,
                method: "PUT",
                body
            })
        }),

        deleteTheme: builder.mutation<
            CommonResponseType & { data: any },
            { id: string | undefined }
        >({
            query: ({ id }) => ({
                url: `${END_POINTS.theme}/${id}`,
                method: "DELETE"
            })
        }),

        //for icons

        postIcon: builder.mutation<
            CommonResponseType & { data: any },
            CommonBody
        >({
            query: (body) => ({
                url: END_POINTS.themeIcon,
                method: "POST",
                body,
            }),
        }),

        getAllIcons: builder.query<
            CommonResponseType & { data: any },
            { page: number, size: number, search: string }
        >({
            query: ({ search, page, size }) => ({
                url: `${END_POINTS.themeIcon}?search=${search}&page=${page}&size=${size}`,
                method: "GET"
            })
        }),

        deleteIcon: builder.mutation<
            CommonResponseType & { data: any },
            { id: string | undefined }
        >({
            query: ({ id }) => ({
                url: `${END_POINTS.themeIcon}/${id}`,
                method: "DELETE"
            })
        }),



    })
})

export const {
    usePostThemeMutation,
    useLazyGetAllThemeQuery,
    useLazyGetThemeByIdQuery,
    useUpdateThemeByIdMutation,
    useDeleteThemeMutation,
    //icons
    usePostIconMutation,
    useLazyGetAllIconsQuery,
    useDeleteIconMutation,

} = ThemeApi;