import {
  Button,
  Card,
  CardContent,
  FormControl,
  Grid,
  MenuItem,
  NativeSelect,
  Select,
  SelectChangeEvent,
  TextField,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import MainContainer from "../../layout/MainContainer";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import Multiselect from "multiselect-react-dropdown";
import { useLazyGetAllUserswithoutPageQuery } from "../../services/manageUser";
import { showError, showToast } from "../../constants/toast";
import * as Yup from "yup";
import { useFormik } from "formik";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import { CommonBody } from "../../types/General";
import { useAddNotificationMutation } from "../../services/notification";


const AddNotification = () => {
  const navigate = useNavigate();
  const [receiver, setReceiver] = useState<string>("All");
  const [selectedUsers, setSelectedUsers] = useState<string[]>([])
  const [typeU, setTypeU] = useState<number>();
  const [getAllUsers] = useLazyGetAllUserswithoutPageQuery();
  const [userDataForNotification, setUserDataForNotification] = useState<any[]>([]);
  const handleReceiverChange = (event: SelectChangeEvent) => {
    setReceiver(event.target.value as string);
  };
  const [addNotification] = useAddNotificationMutation();


  //get all user api
  const getAllUsersFun = async () => {
    try {
      const response = await getAllUsers({
      },).unwrap();
      if (response?.statusCode === 200) {
        setUserDataForNotification(response?.data?.data);
      } else {
        setUserDataForNotification([]);
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };



  const formattedAmenities = [{ name: "All", value: "all" }, ...userDataForNotification.map(user => ({
    value: user._id,
    name: user?.fullName ? (user?.fullName) : (user?.firstName + " " + user?.lastName),
  }))];

  // Function to handle onSelect event
  const handleSelect = (selectedList: any, selectedItem: any) => {
    if (selectedItem.value === "all") {
      setSelectedUsers([]);
      setTypeU(1);
    } else {
      setSelectedUsers(selectedList?.map((option: { value: any; }) => option?.value))
      setTypeU(2);
    }
  };

  console.log(selectedUsers, "selectedUsers");
  // Function to handle onRemove event
  const handleRemove = (selectedList: any, removedItem: any) => {
    if (removedItem.value === "all") {
      setTypeU(2);
      setSelectedUsers([]);
    } else {
      setSelectedUsers(selectedList?.map((option: { value: any; }) => option?.value))
      const isAllSelected = selectedList.some((item: { value: string; }) => item.value === "all");
      if (isAllSelected) {
        setTypeU(1);
      }
    }
  };


  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      title: "",
      messages: "",
      type: "",
    },
    validationSchema: Yup.object({
      title: Yup.string()
        .required("Title is required")
        .min(2, "Minimum 2 characters are required")
        .max(80, "Maximum 80 characters are allowed"),


      messages: Yup.string()
        .required("Meassage is required")
        .min(2, "Minimum 2 characters are required")
        .max(300, "Maximum 300 characters are allowed"),

    }),

    onSubmit: async (values) => {
      formik.setSubmitting(true);
      let data: { title: string; messages: string; userId?: string[]; type: number | undefined } = {
        title: values.title,
        messages: values.messages,
        type: typeU,
      };

      // Add userId only if needed
      if (selectedUsers.length > 0 || typeU === 2) {
        data.userId = selectedUsers;
      }
      console.log(data, "body for notification")

      if (typeU === 2 && selectedUsers.length === 0) {
        showError('Please select atleast one user')
        return;
      }

      try {
        let encryptedBody = generateEncryptedKeyBody(data) as CommonBody;
        const response = await addNotification(encryptedBody).unwrap();
        if (response?.statusCode === 200) {
          showToast("Notification added successfully!");
          navigate("/manage-notifications", { replace: true });
        }
      } catch (error: any) {
        showError(error?.data?.message || "");
      }

    },
  });


  useEffect(() => {
    getAllUsersFun();
  }, [])


  return (
    <>
      <MainContainer>
        <div className="main_loyout">
          <div className="dashboard">
            <h1 className="mn_hdng">Add Notification</h1>
            <Button
              className="btn btn_primary"
              onClick={() => {
                navigate("/manage-notifications");
              }}
            >
              Back
            </Button>
          </div>
          <Card className="cards">
            <form onSubmit={formik.handleSubmit}>
              <CardContent sx={{ p: 1 }}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Typography className="custom_label">
                      Notification Title
                    </Typography>
                    <TextField
                      hiddenLabel
                      type={"text"}
                      className="text_field"
                      name="title"
                      variant="outlined"
                      fullWidth
                      placeholder="Title"
                      inputProps={{ maxLength: 30 }}
                      onBlur={formik.handleBlur}
                      value={formik.values.title}
                      onChange={(val) => {
                        formik.handleChange(val);
                      }}
                      helperText={formik.touched.title && formik.errors.title}

                    ></TextField>
                  </Grid>
                  <Grid item xs={6} />

                  <Grid item xs={6}>
                    <Typography className="custom_label">
                      Notification Message
                    </Typography>
                    <TextField
                      hiddenLabel
                      multiline
                      minRows={4}
                      className="text_field"
                      type={"text"}
                      name="messages"
                      // variant="outlined"
                      fullWidth
                      placeholder="Message"
                      inputProps={{ maxLength: 300 }}
                      onBlur={formik.handleBlur}
                      value={formik.values.messages}
                      onChange={(val) => {
                        formik.handleChange(val);
                      }}
                      helperText={formik.touched.messages && formik.errors.messages}
                    ></TextField>
                  </Grid>
                  <Grid item xs={6} />

                  <Grid item xs={6}>
                    <Typography className="custom_label">Select Receiver</Typography>
                    <FormControl sx={{ width: "100%" }}>
                      <Multiselect
                        className='users_autocomplete'
                        options={formattedAmenities}
                        displayValue="name"
                        onSelect={handleSelect}
                        onRemove={handleRemove}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
                <div className="form_btn">
                  <Button
                    size="large"
                    type="submit"
                    className="btn btn_primary"
                  >
                    Save
                  </Button>
                </div>
              </CardContent>
            </form>
          </Card>
        </div>
      </MainContainer >
    </>
  );
};

export default AddNotification;
