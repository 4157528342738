import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Grid,
  TextField,
  Typography,
  Input,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Checkbox,
  TableBody,
  Tabs,
  Tab,
  SelectChangeEvent,
  FormControl,
  Select,
  MenuItem,
  Switch,
  IconButton,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

import MainContainer from "../../layout/MainContainer";
import { useNavigate, useParams } from "react-router-dom";
import { ChangeEvent, useState } from "react";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import CancelIcon from "@mui/icons-material/Cancel";
import { error } from "console";

import PhoneInput from "react-phone-input-2";
import React from "react";
import { useDeleteRoleMutation, useLazyGetAllRolesQuery, useLazyGetAllRolesWithoutPaginationQuery, useLazyGetRoleByIdQuery, useLazyGetSubAdminByIdQuery, usePostRoleMutation, usePostSubAdminMutation, useUpdateRoleMutation, useUpdateSubAdminMutation } from "../../services/subAdmin";
import { showError, showToast } from "../../constants/toast";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import { CommonBody } from "../../types/General";
import { UploadMedia } from "../../utils/mediaUpload";
import { WarnModal } from "../../components";


interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
interface Role {
  isBlocked: boolean | undefined;
  role: any;
  _id: string;
}
const AddSubAdmin = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [role, setRole] = useState("");
  const [newRole, setNewRole] = useState("");
  const [rolesData, setRolesData] = useState<any[]>([]);
  const [error, setError] = useState(false);
  const [image, setImage] = useState<string>("");
  const [fileName, setFileName] = useState<string>("");
  const [value, setValue] = useState<number>(0);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [selectedRole, setSelectedRole] = useState("");
  const [phone, setPhone] = useState("");
  const [phoneDisplay, setPhoneDisplay] = useState("");
  const [phoneCode, setPhoneCode] = useState("");
  const [open, setOpen] = useState(false);
  const [selectedId, setSelectedId] = useState("");
  const [addRole] = usePostRoleMutation();
  const [updateRole] = useUpdateRoleMutation();
  const [deleteRole] = useDeleteRoleMutation();
  const [getRoleById] = useLazyGetRoleByIdQuery();
  const [getRolesWithoutPage] = useLazyGetAllRolesWithoutPaginationQuery();
  const [addSubAdmin] = usePostSubAdminMutation();
  const [updateSubAdmin] = useUpdateSubAdminMutation();
  const [getSubAdminById] = useLazyGetSubAdminByIdQuery();

  const getSubAdminByIdData = async () => {
    try {
      const response = await getSubAdminById({ id: id }).unwrap();
      if (response.statusCode == 200) {
        console.log("asdsaas", response);
        setName(response?.data?.firstName);
        setEmail(response?.data?.email);
        setPhone(response?.data?.phone);
        setPhoneCode(response?.data?.countryCode);
        setPhoneDisplay(response?.data?.countryCode + response?.data?.phone);
        setImage(response?.data?.image);
        setSelectedRole(response?.data?.permissionId)
      }
    }
    catch (error: any) {
      showError(error.data.message);
    }
  }


  const handleAddRole = async (event: React.FormEvent) => {
    event.preventDefault();
    if (newRole == "") {
      showError("Role name is required");
      return;
    }
    if (newRole.length > 60) {
      showError("Role name can't exceed 60 characters");
    }

    const body = {
      roleName: newRole,
      permission: permissions
    }
    const encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
    try {
      const response = await addRole(encryptedBody).unwrap();
      if (response.statusCode == 200) {
        showToast("Role added successfully");
        getRolesList();
        setPermissions((prevData: any) => {
          return prevData.map((row: any) => {
            return { ...row, isView: false, isDelete: false, isAdd: false }
          })
        })
        setNewRole("");
      }
    }
    catch (error: any) {
      showError(error.data.message);
    }
  };

  const handleAddUpdateSubAdmin = async () => {
    try {
      const body = {
        image: image,
        permissionId: selectedRole,
        firstName: name,
        email: email,
        phone: phone,
        countryCode: phoneCode
      }
      console.log(body, "body for add subadmin");

      const encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
      const response = (id ? await updateSubAdmin({ id: id, body: encryptedBody }).unwrap() : await addSubAdmin(encryptedBody).unwrap());
      if (response.statusCode == 200) {
        showToast("Subadmin added succesfully");
        navigate('/manage-subadmin');
      }
    }
    catch (error: any) {
      showError(error.data.message);
    }
  }

  const handleDelete = async (id: string) => {
    try {
      const response = await deleteRole({ id }).unwrap();
      if (response?.statusCode === 200) {
        showToast(response?.message || "");
        getRolesList();
      }
    } catch (error: any) {
      console.log(error, "errror");
      showError(error?.data?.message || "");
    }
  };

  const handleRoleDataChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewRole(event.target.value);
  };

  const handleRoleChange = (event: SelectChangeEvent) => {
    setRole(event.target.value as string);
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  // const handleStatusChange = async (index: number) => {
  //   try {
  //     const body = {
  //       isBlocked: !rolesData[index]?.isBlocked
  //     };
  //     console.log(body);
  //     const encryptedData = generateEncryptedKeyBody(body) as CommonBody;
  //     const response = await updateRole({ id: `${rolesData[index]._id}`, body: encryptedData }).unwrap();
  //     showToast(response?.message || "Role Updated Successfully");
  //     setRolesData((prevData: any) => {
  //       let temp = [...prevData];
  //       temp[index] = { ...temp[index], isBlocked: !temp[index].isBlocked };
  //       return temp;
  //     });
  //   } catch (error: any) {
  //     showError(error?.data?.message || "");
  //     //      console.log(error);
  //   }
  // }

  const handleImageUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files = event.target;
    const file = files?.files?.length ? files?.files[0] : "";
    const res = await UploadMedia(file);
    if (res?.statusCode === 200) {
      setImage(res?.data);
    } else {
      showError(res?.message);
    }
  };

  console.log(rolesData, "roles data");


  const getRolesList = async () => {
    try {
      const response = await getRolesWithoutPage({}).unwrap();
      if (response?.statusCode === 200) {
        console.log("ROLE", response);
        setRolesData(response?.data?.permission || []);
        // setTotalCount(response?.data?.count);
      } else {
        setRolesData([]);
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };

  const handleChangePhone = (phone: any, country: any) => {
    setPhone(phone.replace(country.dialCode, ""));
    setPhoneCode(country.dialCode);
    setPhoneDisplay(phone);
  };


  const label = { inputProps: { "aria-label": "Switch demo" } };
  const [permissions, setPermissions] = useState([
    {
      label: "Dashboard",
      isAdd: false,
      isView: false,
      isDelete: false,
      disabled: true
    },
    {
      label: "Manage Users",
      isAdd: false,
      isView: false,
      isDelete: false,
      disabled: true
    },
    {
      label: "Manage Themes",
      isAdd: false,
      isView: false,
      isDelete: false,
      disabled: true
    },
    {
      label: "Manage Ad's",
      isAdd: false,
      isView: false,
      isDelete: false,
      disabled: true
    },
    // {
    //   label: "Manage Icons",
    //   isAdd: false,
    //   isView: false,
    //   isDelete: false,
    //   disabled: true
    // },
    {
      label: "Notification Management",
      isAdd: false,
      isView: false,
      isDelete: false,
      disabled: true
    },
    {
      label: "Manage Cms",
      isAdd: false,
      isView: false,
      isDelete: false,
      disabled: true
    }
  ])

  React.useEffect(() => {
    if (id)
      getSubAdminByIdData();
  }, [])
  React.useEffect(() => {
    getRolesList();
  }, [rolesData])

  return (
    <>
      <MainContainer>
        <div className="main_loyout">
          <div className="dashboard">
            <h1 className="mn_hdng">{id ? "Edit Sub-Admin" : "Add Sub-Admin"}</h1>
            <Button
              className="btn btn_primary"
              onClick={() => {
                navigate("/manage-subadmin");
              }}
            >
              Back
            </Button>
          </div>
          <Card className="cards">
            <Box className="custom_tabs">
              <Box className="flx_sc">
                <Tabs
                  value={value}
                  onChange={handleChange}
                  variant="scrollable"
                  scrollButtons="auto"
                  aria-label="basic tabs example"
                  className="custom_tabs_links"
                >
                  <Tab label="Add Role" {...a11yProps(0)} />
                  <Tab label={id ? "Edit Sub-admin" : "Add Sub-admin"} {...a11yProps(1)} />
                </Tabs>
              </Box>
              <CustomTabPanel value={value} index={0}>
                <form
                  onSubmit={handleAddRole}
                >
                  <CardContent sx={{ p: 1 }}>
                    <Grid container spacing={2}>
                      <Grid item lg={4} md={4} sm={4} xs={12}>
                        <Typography className="custom_label">
                          Add Role
                        </Typography>
                        <TextField
                          hiddenLabel
                          type={"text"}
                          name="title"
                          variant="outlined"
                          value={newRole}
                          fullWidth
                          placeholder="Add Role"
                          onChange={(event) => {
                            if (event.target.value.length <= 60)
                              handleRoleDataChange(event as any)
                            if (event.target.value.length > 60)
                              showError("Role name cant exceed 60 characters");
                          }
                          }
                        />
                      </Grid>
                    </Grid>

                    <Grid item xs={12}>
                      <TableContainer className="table_container">
                        <Box className="heading"></Box>
                        <Table
                          sx={{ minWidth: 650 }}
                          aria-label="simple table"
                        >
                          <TableHead>
                            <TableRow>
                              <TableCell className="mn_hdng">
                                Module
                              </TableCell>
                              <TableCell className="mn_hdng">
                                Add/Edit
                              </TableCell>
                              <TableCell className="mn_hdng">
                                View
                              </TableCell>
                              <TableCell className="mn_hdng">
                                Delete
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {permissions.map((row: any, i: number) => (
                              <TableRow>
                                <TableCell className="mn_hdng">
                                  {row.label}
                                </TableCell>
                                <TableCell>
                                  <Checkbox {...label} checked={row.isAdd} onChange={() => {
                                    if (row.isAdd == false && row.isView == false) {
                                      setPermissions((prevData) => {
                                        return prevData.map((row: any, index: number) => {
                                          if (index == i) {
                                            return { ...row, isView: true }
                                          }
                                          return row;
                                        })
                                      })
                                    }
                                    setPermissions((prevData: any) => {
                                      return prevData.map((row: any, index: number) => {
                                        if (i == index) {
                                          return { ...row, isAdd: !row.isAdd }
                                        }
                                        return row;

                                      })
                                    });
                                  }} />
                                </TableCell>
                                <TableCell>
                                  <Checkbox {...label}
                                    onChange={() => {

                                      if (row.isView == true && (row.isAdd == true || row.isDelete == true)) {
                                        setPermissions((prevData: any) => {
                                          return prevData.map((row: any, index: number) => {
                                            if (index == i) {
                                              return { ...row, isDelete: false, isAdd: false }
                                            }
                                            else
                                              return row;
                                          });
                                        })
                                      }
                                      setPermissions((prevData: any) => {
                                        return prevData.map((row: any, index: number) => {
                                          if (index == i) {
                                            return { ...row, isView: !row.isView }
                                          }
                                          return row;
                                        })
                                      })
                                    }}
                                    checked={row.isView} />
                                </TableCell>
                                <TableCell>
                                  <Checkbox {...label}
                                    onChange={() => {
                                      if (row.isDelete == false && row.isView == false) {
                                        setPermissions((prevData) => {
                                          return prevData.map((row: any, index: number) => {
                                            if (index == i) {
                                              return { ...row, isView: true }
                                            }
                                            return row;
                                          })
                                        })
                                      }
                                      setPermissions((prevData) => {
                                        return prevData.map((row: any, index: number) => {
                                          if (index == i) {
                                            return { ...row, isDelete: !row.isDelete }
                                          }
                                          return row;
                                        })
                                      })

                                    }}
                                    checked={row.isDelete} />
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>
                    <div className="form_btn">
                      <Button
                        size="large"
                        type="submit"
                        className="btn btn_primary"
                      >
                        Add
                      </Button>
                    </div>
                  </CardContent>
                </form>
                {/* <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Role</TableCell>
                        <TableCell>Enabled</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rolesData.map((role, index) => (
                        <TableRow key={index}>
                          <TableCell>{role.name}</TableCell>
                          <TableCell>
                            <Switch
                              checked={role.enabled}
                              onChange={() => handleToggle(index)}
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer> */}

                <Grid item xs={12}>
                  <TableContainer className="table_container">
                    <Box className="heading"></Box>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell className="mn_hdng">S.No.</TableCell>
                          <TableCell className="mn_hdng">Role</TableCell>
                          {/* <TableCell className="mn_hdng">Status</TableCell> */}
                          <TableCell className="mn_hdng">Delete</TableCell>
                        </TableRow>
                      </TableHead>
                      {rolesData?.length ? (
                        rolesData?.map((row, i) => (
                          <TableBody>
                            <TableRow>
                              <TableCell className="mn_hdng">1</TableCell>
                              <TableCell>{row?.roleName}</TableCell>
                              {/* <TableCell>
                                <Switch
                                  checked={!row.isBlocked}
                                  // checked={row.enabled}
                                  onChange={()=>handleStatusChange(i)}
                                  // onChange={() => handleToggle(index)}
                                />
                              </TableCell> */}
                              <TableCell>
                                <IconButton onClick={() => {
                                  setSelectedId(row?._id);
                                  setOpen(true);
                                }}>
                                  <DeleteIcon />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        ))
                      ) : (
                        <TableBody
                          sx={{
                            padding: "20px",
                            textAlign: "center",
                            width: "100%",
                          }}
                        >
                          <TableRow

                          // sx={{ padding: "20px", textAlign: "center" }}
                          >
                            <TableCell
                              colSpan={5}
                              sx={{ padding: "20px", textAlign: "center" }}
                            >
                              No Roles Added
                            </TableCell>{" "}
                            {/* No Roles Added */}
                          </TableRow>
                        </TableBody>
                      )}
                    </Table>
                  </TableContainer>
                </Grid>
              </CustomTabPanel>
              <CustomTabPanel value={value} index={1}>
                <form>
                  <CardContent sx={{ p: 1 }}>
                    <Grid container spacing={2}>
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Typography className="custom_label">Image</Typography>
                        {image ? (
                          <div className="upload_image_preview">
                            <CardMedia component="img" image={image} alt="photo" />
                            <CancelIcon
                              sx={{ cursor: "pointer" }}
                              onClick={() => {
                                setImage("");
                              }}
                            />
                          </div>
                        ) : (
                          <Box className="upload_image">
                            <label htmlFor="icon-button-file">
                              <Input
                                sx={{ display: "none" }}
                                id="icon-button-file"
                                type="file"
                                inputProps={{
                                  accept: "image/png,image/jpeg",
                                }}
                                onChange={handleImageUpload}
                              />
                              <Button component="span" className="upload_image_btn">
                                <img
                                  src={
                                    image
                                      ? image
                                      : "/static/images/user_placeholder.png"
                                  }
                                  alt=""
                                />
                                <CameraAltIcon />
                              </Button>
                            </label>
                          </Box>
                        )}
                        {error && !image ? (
                          <h6 className="err_msg">This field is required</h6>
                        ) : (
                          ""
                        )}
                      </Grid>
                      <Grid item lg={4} md={4} sm={4} xs={12}>
                        <Typography className="custom_label">
                          Full Name
                        </Typography>
                        <TextField
                          hiddenLabel
                          type={"text"}
                          name="firstName"
                          value={name}
                          onChange={(e) => {
                            if (e.target.value.length <= 50)
                              setName(e.target.value)
                            if (e.target.value.length > 50)
                              showError("Sub-Admin name cant exceed 50 characters")

                          }}
                          variant="outlined"
                          fullWidth
                          placeholder="Name"
                        ></TextField>
                      </Grid>
                      <Grid item lg={4} md={4} sm={4} xs={12}>
                        <Typography className="custom_label">Email</Typography>
                        <TextField
                          hiddenLabel
                          required
                          type={"email"}
                          value={email}
                          onChange={(e) => {
                            if (e.target.value.length <= 80)
                              setEmail(e.target.value)
                            if (e.target.value.length > 80)
                              showError("Email cant exceed 80 characters")
                          }
                          }
                          name="message"
                          variant="outlined"
                          fullWidth
                          placeholder="Email"
                        ></TextField>
                      </Grid>
                      <Grid item lg={4} md={4} sm={4} xs={12}>
                        <Typography className="custom_label">
                          Phone Number
                        </Typography>
                        <PhoneInput
                          country={"us"}
                          placeholder="0 (000) 000-000"
                          enableSearch={true}
                          inputStyle={{ width: "100%" }}
                          value={phoneDisplay}
                          // onChange={(value) => formik.setFieldValue("phone", value)}
                          onChange={(phone, country) =>
                            handleChangePhone(phone, country)
                          }
                          isValid={!(error && !phone)}
                        />
                        {error && !phone && (
                          <Typography variant="caption" color="error">
                            Phone number is required
                          </Typography>
                        )}
                      </Grid>
                      <Grid item lg={4} md={4} sm={4} xs={12}>
                        <Typography className="custom_label">
                          Select Role
                        </Typography>

                        <FormControl sx={{ width: "100%" }}>
                          <Select
                            className="select_div"
                            fullWidth
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={selectedRole}
                            inputProps={{
                              "aria-label": "Without label",
                            }}
                            displayEmpty
                            onChange={(e) => { setSelectedRole(e.target.value) }}
                          >
                            <MenuItem value="" disabled>
                              Select
                            </MenuItem>
                            {rolesData?.map((row: any, index: number) => {
                              return <MenuItem value={row?._id}>{row?.roleName}</MenuItem>
                            })}
                          </Select>
                        </FormControl>
                      </Grid>
                      {/* <Grid item lg={8} md={8} sm={8} xs={12} />
                      <Grid item lg={4} md={4} sm={4} xs={12}>
                        <Button
                          className="btn btn_primary"
                        >
                          Save
                        </Button>
                      </Grid> */}


                    </Grid>
                    <div className="form_btn">
                      <Button
                        size="large"
                        type="submit"
                        className="btn btn_primary"
                        onClick={(e) => {
                          e.preventDefault();
                          if (name == "") {
                            showError("Full name is required")
                            return;
                          }
                          if (name.length > 50) {
                            showError("Full name can't exceed 50");
                            return;
                          }
                          if (email == "") {
                            showError("Email is required")
                            return;
                          }
                          // if (!isValidEmail(email)) {
                          //   showError("Enter valid email");
                          //   return;
                          // }
                          if (phone == "") {
                            showError("Phone is required")
                            return;
                          }
                          if (phone.length < 6 || phone.length > 16) {
                            showError("Invalid phone")
                            return;
                          }
                          if (selectedRole == "") {
                            showError("Selection of role is required")
                            return;
                          }
                          handleAddUpdateSubAdmin();
                        }}
                      >
                        {id ? "Update" : "Save"}
                      </Button>
                    </div>
                  </CardContent>
                </form>
              </CustomTabPanel>
            </Box>
          </Card>
          <WarnModal
            setOpen={setOpen}
            open={open}
            name={"role"}
            handleDelete={() => handleDelete(selectedId)}
          />
        </div>
      </MainContainer>
    </>
  );
};

export default AddSubAdmin;
