import React, { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import {
  Button,
  Card,
  IconButton,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";

const SubCategories = () => {
  const navigate = useNavigate();

  const label = { inputProps: { "aria-label": "Switch demo" } };

  return (
    <MainContainer>
      <div className="main_loyout">
        <div className="dashboard">
          <h1>View Sub-Categories</h1>
        </div>
        <Card className="cards">
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <h4 style={{ margin: 0 }}>Category Name</h4>
            <Button
              className="btn btn_primary"
              onClick={() => navigate("/manage-categories")}
            >
              Back
            </Button>
          </Box>

          <TableContainer className="table_container">
            <Box className="heading"></Box>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">S.No</TableCell>
                  <TableCell align="center">Image</TableCell>
                  <TableCell>Sub-Category Name</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                <TableRow>
                  <TableCell align="center">{1}</TableCell>
                  <TableCell>
                    {" "}
                    <figure className="user_img">
                      <img src="/static/images/user_placeholder.png" alt="" />
                    </figure>
                  </TableCell>
                  <TableCell>Emergency Fund</TableCell>

                  <TableCell>
                    {" "}
                    <Switch {...label} defaultChecked size="small" />
                  </TableCell>
                  <TableCell>
                    <Box className="table_actions">
                      <IconButton
                        onClick={() =>
                          navigate("/manage-categories/add", { state: "GOAL" })
                        }
                      >
                        <ModeEditIcon />
                      </IconButton>
                      <IconButton>
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
      </div>
    </MainContainer>
  );
};

export default SubCategories;
