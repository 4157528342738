import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Input,
  TextField,
  Typography,
} from "@mui/material";
import * as Yup from "yup";
import { useFormik } from "formik";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import { ChangeEvent, useEffect, useState } from "react";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import CancelIcon from "@mui/icons-material/Cancel";
import MainContainer from "../../../layout/MainContainer";
import { useNavigate, useParams } from "react-router-dom";
import { useLazyGetUserByIdQuery, usePostUserMutation, useUpdateUserByIdMutation } from "../../../services/manageUser";
import { showError, showToast } from "../../../constants/toast";
import { UploadMedia } from "../../../utils/mediaUpload";
import { generateEncryptedKeyBody } from "../../../utils/crypto";
import { CommonBody } from "../../../types/General";

const UsersForm = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [phoneCode, setPhoneCode] = useState("+91");
  const [image, setImage] = useState<string>("");
  const [userData, setUserData] = useState<any>();
  const [getUserById, { isLoading }] = useLazyGetUserByIdQuery();
  const [editUser] = useUpdateUserByIdMutation();
  const [addUser] = usePostUserMutation();


  const getUserDetail = async (id: string) => {
    try {
      const response = await getUserById({
        id: id,
      }).unwrap();
      if (response?.statusCode === 200) {
        setUserData(response?.data);
        setPhoneCode(response?.data?.countryCode || "+91");
        setImage(response?.data?.image || "");
      }
    } catch (error: any) {
      console.log(error, "errror");
      showError(error?.data?.message || "");
    }
  };


  const handleChangePhone = (phone: any, country: any) => {
    formik.setFieldValue("phone", phone?.replace(country.dialCode, ""));
    setPhoneCode(country?.dialCode);
  };

  const handleImageUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files = event.target;
    const file = files?.files?.length ? files?.files[0] : "";
    const res = await UploadMedia(file);
    if (res?.statusCode === 200) {
      setImage(res?.data);
    } else {
      showError(res?.message);
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: userData?.email || "",
      firstName: userData?.firstName || "",
      lastName: userData?.lastName || "",
      countryCode: userData?.countryCode || "",
      phone: userData?.phone || "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .required("Email is required")
        .matches(
          /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
          "Enter a valid email address"
        ),
      firstName: Yup.string()
        .required("First name is required")
        .max(30, "Maximum 60 character are allowed")
        .min(2, "Minimum 2 characters are required"),

      phone: Yup.string()
        .required("Phone number is required")
        .min(6, "Phone number must be at least 6 characters")
        .max(16, "Phone number must be at least 16 characters"),
    }),
    onSubmit: async (values) => {
      formik.setSubmitting(true);

      const data: {
        image: string;
        email: string;
        firstName: string;
        lastName?: string;
        countryCode: string;
        phone: string;
      } = {
        image: image,
        email: formik.values.email,
        firstName: formik.values.firstName,
        countryCode: (phoneCode.includes("+") ? "" : "+") + phoneCode,
        phone: formik.values.phone,
      };
      if (formik.values.lastName?.length > 0) {
        data['lastName'] = formik.values.lastName;
      }


      const Body: {
        image?: string;
        email?: string;
        firstName?: string;
        lastName?: string;
        countryCode?: string;
        phone?: string;
      } = {};

      if (formik.values.firstName !== userData?.firstName) {
        Body['firstName'] = formik.values.firstName;
      }
      if (formik.values.lastName !== userData?.lastName) {
        Body['lastName'] = formik.values.lastName;
      }
      if (formik.values.email !== userData?.email) {
        Body['email'] = formik.values.email;
      }
      if (formik.values.phone !== userData?.phone) {
        Body['phone'] = formik.values.phone;
      }
      if ((phoneCode.includes("+") ? "" : "+") + phoneCode !== userData?.countryCode) {
        Body['countryCode'] = (phoneCode.includes("+") ? "" : "+") + phoneCode;
      }
      if (image !== userData?.image) {
        Body['image'] = image;
      }


      console.log(Body, "Body for edit");

      try {
        if (id) {
          let encryptedBody = generateEncryptedKeyBody(Body) as CommonBody;
          const response = await editUser({ id: `${id}`, body: encryptedBody }).unwrap();
          showToast(response?.message || "User Updated Successfully");
          navigate("/manageUsers");
        } else {
          let encryptedBody = generateEncryptedKeyBody(data) as CommonBody;
          const response = await addUser(encryptedBody).unwrap();
          showToast(response?.message || "User Added Successfully");
          navigate("/manageUsers");
        }
      } catch (error: any) {
        showError(error?.data?.message || "");
        console.log(error);
      }
    },
  });


  useEffect(() => {
    if (id) {
      getUserDetail(id);
    }
  }, []);




  return (
    <>
      <MainContainer>
        <div className="main_loyout">
          <div className="dashboard">
            <h1 className="mn_hdng">{id ? "Edit user" : "Add user"} </h1>
            <Button
              className="btn btn_primary"
              onClick={() => {
                navigate("/manageUsers");
              }}
            >
              Back
            </Button>
          </div>
          <Card className="cards">
            <form onSubmit={formik.handleSubmit}>
              <CardContent sx={{ p: 1 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography className="custom_label">Image</Typography>
                    {image ? (
                      <div className="upload_image_preview">
                        <CardMedia component="img" image={image} alt="photo" />
                        <CancelIcon
                          onClick={() => {
                            setImage("");
                          }}
                        />
                      </div>
                    ) : (
                      <Box className="upload_image">
                        <label htmlFor="icon-button-file">
                          <Input
                            sx={{ display: "none" }}
                            id="icon-button-file"
                            type="file"
                            inputProps={{
                              accept: "image/png,image/jpeg",
                            }}
                            onChange={handleImageUpload}
                          />
                          <Button component="span" className="upload_image_btn">
                            <img
                              src={
                                image
                                  ? image
                                  : "/static/images/user_placeholder.png"
                              }
                              alt=""
                            />
                            <CameraAltIcon />
                          </Button>
                        </label>
                      </Box>
                    )}
                  </Grid>
                  <Grid item xs={4}>
                    <Typography className="custom_label">First Name</Typography>
                    <TextField
                      hiddenLabel
                      type={"text"}
                      name="firstName"
                      variant="outlined"
                      className="text_field"
                      fullWidth
                      placeholder="First Name"
                      id="firstName"
                      onBlur={formik.handleBlur}
                      value={formik.values.firstName}
                      onChange={(val) => formik.handleChange(val)}
                      helperText={
                        formik.touched.firstName && typeof formik.errors.firstName === 'string'
                          ? formik.errors.firstName
                          : undefined
                      }
                      inputProps={{ maxLength: 60 }}
                    ></TextField>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography className="custom_label">Last Name</Typography>
                    <TextField
                      hiddenLabel
                      type={"text"}
                      name="lastName"
                      className="text_field"
                      variant="outlined"
                      fullWidth
                      placeholder="Last Name"
                      id="lastName"
                      onBlur={formik.handleBlur}
                      value={formik.values.lastName}
                      onChange={(val) => formik.handleChange(val)}
                      helperText={
                        formik.touched.lastName && typeof formik.errors.lastName === 'string'
                          ? formik.errors.lastName
                          : undefined
                      }
                      inputProps={{ maxLength: 60 }}
                    ></TextField>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography className="custom_label">Email</Typography>
                    <TextField
                      hiddenLabel
                      type={"email"}
                      name="email"
                      variant="outlined"
                      fullWidth
                      className="text_field"
                      placeholder="Email"
                      id="email"
                      onBlur={formik.handleBlur}
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      helperText={
                        formik.touched.email && typeof formik.errors.email === 'string'
                          ? formik.errors.email
                          : undefined
                      }
                    ></TextField>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography className="custom_label">
                      Phone Number
                    </Typography>
                    <PhoneInput
                      enableSearch={true}
                      country={"us"}
                      placeholder="Phone Number"
                      inputClass="phoneInput"
                      value={phoneCode + formik.values.phone}
                      inputStyle={{ width: "100%" }}
                      onChange={(phone, country) =>
                        handleChangePhone(phone, country)
                      }
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.phone && formik.errors.phone && (
                      <h6 className="err_msg">
                        {typeof formik.errors.phone === 'string' && formik.errors.phone}
                      </h6>
                    )}
                  </Grid>
                </Grid>
                <div className="form_btn">
                  <Button
                    size="large"
                    type="submit"
                    className="btn btn_primary"
                  >
                    Save
                  </Button>
                </div>
              </CardContent>
            </form>
          </Card>
        </div>
      </MainContainer>
    </>
  );
};

export default UsersForm;
