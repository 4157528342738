import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Grid,
  TextField,
  Typography,
  Input,
  FormControl,
  Select,
  MenuItem,
  SelectChangeEvent,
} from "@mui/material";
import MainContainer from "../../layout/MainContainer";
import { useLocation, useNavigate } from "react-router-dom";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import CancelIcon from "@mui/icons-material/Cancel";
import { ChangeEvent, useState } from "react";
import { UploadMedia } from "../../utils/mediaUpload";
import { showError, showToast } from "../../constants/toast";
import { usePostIconMutation } from "../../services/themes";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import { CommonBody } from "../../types/General";

const AddIcons = () => {
  const navigate = useNavigate();
  const [image, setImage] = useState<string>("");
  const [addIcon] = usePostIconMutation();
  const [title, setTitle] = useState<string>("");

  const handleImageUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files = event.target;
    const file = files?.files?.length ? files?.files[0] : "";
    const res = await UploadMedia(file);
    if (res?.statusCode === 200) {
      setImage(res?.data);
    } else {
      showError(res?.message);
    }
  };

  const handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(event.target.value);
  };


  const addIconFun = async () => {
    const body = {
      icon: image,
      title: title
    }
    try {
      let encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
      const res = await addIcon(encryptedBody).unwrap();
      if (res?.statusCode === 200) {
        showToast("Icon added successfully")
        navigate("/manage-icons");
      }
    } catch (error: any) {
      showError(error?.message);
    }
  }



  return (
    <>
      <MainContainer>
        <div className="main_loyout">
          <div className="dashboard">
            <h1>Add Icons</h1>
            <Button
              className="btn btn_primary"
              onClick={() => {
                navigate("/manage-icons");
              }}
            >
              Back
            </Button>
          </div>
          <Card className="cards">
            <form>
              <CardContent sx={{ p: 1 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography className="custom_label">Tab icon</Typography>
                    {image ? (
                      <div className="upload_image_preview">
                        <CardMedia component="img" image={image} alt="photo" />
                        <CancelIcon
                          onClick={() => {
                            setImage("");
                          }}
                        />
                      </div>
                    ) : (
                      <Box className="upload_image">
                        <label htmlFor="icon-button-file">
                          <Input
                            sx={{ display: "none" }}
                            id="icon-button-file"
                            type="file"
                            inputProps={{
                              accept: "image/png,image/jpeg",
                            }}
                            onChange={handleImageUpload}
                          />
                          <Button component="span" className="upload_image_btn">
                            <img
                              src={
                                image
                                  ? image
                                  : "/static/images/download.png"
                              }
                              alt=""
                            />
                            <CameraAltIcon />
                          </Button>
                        </label>
                      </Box>
                    )}
                  </Grid>


                  <Grid item xs={4}>
                    <Typography className="custom_label">
                      Tab title
                    </Typography>
                    <TextField
                      hiddenLabel
                      type={"text"}
                      name="title"
                      variant="outlined"
                      fullWidth
                      onChange={handleTitleChange}
                      value={title}
                      placeholder="Title"
                    />
                  </Grid>
                </Grid>
                <div className="form_btn">
                  <Button
                    size="large"
                    className="btn btn_primary"
                    onClick={addIconFun}
                  >
                    Save
                  </Button>
                </div>
              </CardContent>
            </form>
          </Card>
        </div>
      </MainContainer>
    </>
  );
};

export default AddIcons;
