import React, { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import UploadIcon from '@mui/icons-material/Upload';
import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Box,
  Switch,
  Button,
  Tooltip,
  Input,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import SearchBar from "../../components/SearchBar";
import { isValidInput } from "../../utils/validations";
import { useDeleteUserMutation, useLazyGetAllUsersQuery, useLazyGetUserCSVQuery, useUpdateUserByIdMutation, useUploadCsvMutation } from "../../services/manageUser";
import { showError, showToast } from "../../constants/toast";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import { CommonBody, Permissions } from "../../types/General";
import moment from "moment";
import * as XLSX from 'xlsx';
import { Pagination, WarnModal } from "../../components";
import Loader from "../../constants/Loader";
import { UploadMedia } from "../../utils/mediaUpload";
import useAuth from "../../hooks/useAuth";

interface ExcelData {
  [key: string]: any;
}

const ManageUsers = () => {
  const navigate = useNavigate();
  function createData(
    name: string,
    userName: string,
    phone: string,
    email: string,
    planName: string,
    planPrice: string,
    postedAds: string,
    earnings: string
  ) {
    return {
      name,
      userName,
      phone,
      email,
      planName,
      planPrice,
      postedAds,
      earnings,
    };
  }
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [updateUser] = useUpdateUserByIdMutation();
  const [getAllUser, { isLoading }] = useLazyGetAllUsersQuery();
  const [deleteUser] = useDeleteUserMutation();
  const [data, setData] = useState<any>();
  const UserData = useAuth();
  const [show, setShow] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [selectedId, setSelectedId] = useState<string>("");
  const [manageUser, setManageUser] = useState<any>([]);
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState<number>(0);
  let totalPages = Math.ceil(totalCount / 10);
  const [uploadFile] = useUploadCsvMutation();
  const [exportCSV] = useLazyGetUserCSVQuery();

  const label = { inputProps: { "aria-label": "Switch demo" } };
  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };

  const handleCsvUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files = event.target;
    const file = files?.files?.length ? files?.files[0] : "";
    const res = await UploadMedia(file);
    if (res?.statusCode === 200) {
      setData(res?.data);
      setShow(true);
    } else {
      showError(res?.message);
    }
  };




  console.log(data, "csv file link");

  const UploadFileFun = async () => {
    const body = {
      awsLink: data
    }
    try {
      let encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
      const res = await uploadFile(encryptedBody).unwrap();
      if (res?.statusCode === 200) {
        console.log("api working");
        showToast("File uploaded successfully")
      }
    } catch (error: any) {
      showError(error?.data?.message || "Something went wrong")
      // console.log(error,"error");
      
    }
  }


  const userPermissions = UserData?.permissions?.[0]?.permission?.length
    ? (UserData?.permissions?.[0]?.permission)
    : [];
  const [hidePermission, setHidePermission] = useState<
    Permissions | null | undefined
  >(null);

  const checkPermission = () => {
    const permission = userPermissions;
    if (permission?.length) {
      let idx = -1;
      idx = permission?.findIndex(
        (ele: Permissions) => ele?.label === "Manage Users"
      );
      if (idx > -1) {
        setHidePermission(permission[idx]);
      } else {
        navigate(-1);
      }
    }
  };



  //get all user api
  const getAllUsers = async () => {
    try {
      const response = await getAllUser({
        page: page,
        search: debouncedSearchTerm.trim(),
        size: 10,
      }).unwrap();
      if (response?.statusCode === 200) {
        setTotalCount(response?.data?.count);
        setManageUser(response?.data?.data || [])
      } else {
        setManageUser([]);
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };

  const handleExportCsv = async () => {
    try {
      const res = await exportCSV({}).unwrap();
      if (res?.statusCode === 200) {
        window.open(res?.data?.location || "");
      }
    } catch (error: any) {
      showError(error?.message);
    }
  };






  //update user status api
  const updateUserStatus = async (id: string, status: boolean) => {
    const data = {
      isBlocked: status,
    };
    try {
      let encryptedBody = generateEncryptedKeyBody(data) as CommonBody;
      const res = await updateUser({ id, body: encryptedBody }).unwrap();
      if (res?.statusCode === 200) {
        showToast("User status updated successfully");
        await getAllUsers();
      }
    } catch (error: any) {
      console.log(error, "error");
      showError(error?.message || "Error updating user status");
    }
  };

  //delete user api
  const handleDeleteUser = async (id: string) => {
    try {
      const res = await deleteUser({ id }).unwrap();
      if (res?.statusCode === 200) {
        showToast("User deleted successfully");
        await getAllUsers();
      }
    } catch (error: any) {
      console.log(error, "errror");
      showError(error?.message || "");
    }
  };


  useEffect(() => {
    checkPermission();
  }, [UserData])

  useEffect(() => {
    getAllUsers();
  }, [page, debouncedSearchTerm])


  return (
    <MainContainer>
      <Loader isLoad={isLoading} />
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">Manage Users</h1>
        </div>
        <Card className="cards">
          <Box className="cards_header">
            <SearchBar
              value={searchTerm}
              searchTerm={searchTerm}
              onCross={() => setSearchTerm("")}
              setDebouncedSearchTerm={setDebouncedSearchTerm}
              onChange={(val: any) => {
                if (isValidInput(val.target.value)) {
                  setSearchTerm(val.target.value);
                }
              }
              }
            />
            <Box className="cards_header_right">
              {!show ? (
                <div className="uploadImg">

                  <Input
                    id="icon-button-file"
                    type="file"
                    inputProps={{ accept: "application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, .csv" }}
                    onChange={handleCsvUpload}
                  />
                  <div className="upload_image_btn upld_csv">

                    <p><FileDownloadIcon /> <b>Choose Csv</b></p>
                  </div>
                </div>
              ) : (
                <Button className="btn btn_primary" onClick={() => { UploadFileFun(); setShow(false) }}>
                  {/* <UploadIcon />Upload CSV */}
                  Click to upload
                </Button>
              )}
              <Button className="btn btn_primary" onClick={() => handleExportCsv()}>
                <FileDownloadIcon /> Export CSV
              </Button>
              {hidePermission?.isAdd || UserData?.role === 1 ? (
                <Button className="btn btn_primary" onClick={() => navigate("/manageusers/usersform")}>
                  Add User
                </Button>
              ) : null}
            </Box>
          </Box>
          <TableContainer className="table_container">
            <Box className="heading"></Box>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">S.No</TableCell>
                  <TableCell>Image</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Phone no</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Date/time</TableCell>
                  <TableCell>Account Status</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              {manageUser?.length ? (
                manageUser?.map((row: any, i: number) => {
                  return (
                    <TableBody key={row?._id}>
                      <TableRow>
                        <TableCell align="center">{" "}
                          {(page - 1) * 10 + i + 1}</TableCell>
                        <TableCell align="center">
                          <figure className="user_img">
                            <img
                              src={row?.image ? (row?.image) : ("/static/images/user_placeholder.png")}
                              alt=""
                            />
                          </figure>
                        </TableCell>
                        <TableCell align="center">{row?.fullName ? (row?.fullName) : (row?.firstName + " " + row?.lastName) || "-"}</TableCell>
                        <TableCell align="center">{row?.countryCode
                          ? (row?.countryCode.includes("+") ? "" : "+") +
                          row?.countryCode
                          : null}{" "}
                          {row?.phone || "-"}</TableCell>
                        <TableCell align="center">{row?.email || "-"}</TableCell>
                        <TableCell align="center">{moment(row?.createdAt).format("DD/MM/YYYY")}</TableCell>

                        <TableCell align="center">
                          <Switch
                            {...label}
                            size="small"
                            checked={!row?.isBlocked}
                            onChange={() =>
                              updateUserStatus(row?._id, !row?.isBlocked)
                            }
                          />
                        </TableCell>
                        <TableCell align="center">
                          <Box className="table_actions">
                            {hidePermission?.isView || UserData?.role === 1 ? (
                              <Tooltip title="View">
                                <IconButton
                                  onClick={() =>
                                    navigate(`/manageusers/userdetails/${row?._id}`)
                                  }
                                >
                                  <VisibilityIcon />
                                </IconButton>
                              </Tooltip>
                            ) : null}
                            {hidePermission?.isAdd || UserData?.role === 1 ? (
                              <Tooltip title="Edit">
                                <IconButton
                                  onClick={() =>
                                    navigate(`/manageusers/edit-user/${row?._id}`)
                                  }
                                >
                                  <ModeEditIcon />
                                </IconButton>
                              </Tooltip>
                            ) : null}
                            {hidePermission?.isDelete || UserData?.role === 1 ? (
                              <Tooltip title="Delete">
                                <IconButton>
                                  <DeleteIcon
                                    onClick={() => {
                                      setOpen(true);
                                      setSelectedId(row?._id);
                                    }} />
                                </IconButton>
                              </Tooltip>
                            ) : null}
                          </Box>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  );
                })
              ) : (
                <TableBody>
                  <TableCell
                    align="center"
                    colSpan={10}
                    sx={{ color: "#051140" }}
                  >
                    No User Found
                  </TableCell>
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Card>
      </div>
      <WarnModal
        name="user"
        setOpen={setOpen}
        open={open}
        handleDelete={() => handleDeleteUser(selectedId)}
      />
      <Pagination
        module={manageUser}
        page={page}
        onPageChange={onPageChange}
        totalPages={totalPages}
      />
    </MainContainer>
  );
};

export default ManageUsers;
