import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import MainContainer from "../../../layout/MainContainer";
import "../Users.scss";
import { useLazyGetUserByIdQuery } from "../../../services/manageUser";
import { showError } from "../../../constants/toast";
import Loader from "../../../constants/Loader";




const UserDetails = () => {
  const navigate = useNavigate();
  const [userData, setUserData] = useState<any>();
  const [getUser, { isLoading }] = useLazyGetUserByIdQuery();
  const { id } = useParams();

  const fetchData = async () => {
    try {
      const res = await getUser({ id }).unwrap();
      if (res?.statusCode === 200) {
        setUserData(res?.data)
      }
    } catch (error: any) {
      showError(error?.message || "Something went wrong")
    }
  }

  console.log(userData, "user data");


  useEffect(() => {
    fetchData();
  }, [])

  return (
    <MainContainer>
      <Loader isLoad={isLoading} />
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">View User Profile</h1>
          <Button
            className="btn btn_primary"
            onClick={() => {
              navigate("/manageUsers");
            }}
          >
            Back
          </Button>
        </div>
        <Card className="cards">
          <CardContent sx={{ p: 1 }}>
            <Grid container spacing={2} className="view_box">
              <Grid item xs={2}>
                <figure className="profile_img">
                  <img src={userData?.image ? userData?.image : "/static/images/user_placeholder.png"} alt="" />
                </figure>
              </Grid>
              <Grid item xs={10} className="view_box_list">
                <Grid container spacing={3}>
                  <Grid item xs={4}>
                    <Box>
                      <Typography component="h5">Name</Typography>
                      <Typography component="p">{userData?.firstName + " " + userData?.lastName}</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={4}>
                    <Box>
                      <Typography component="h5">Phone no</Typography>
                      <Typography component="p">{userData?.countryCode + " " + userData?.phone}</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={4}>
                    <Box>
                      <Typography component="h5">Email</Typography>
                      <Typography component="p">
                        {userData?.email || "-"}
                      </Typography>
                    </Box>
                  </Grid>

                  <Grid item xs={4}>
                    <Box>
                      <Typography component="h5">Account Status</Typography>
                      <Typography component="p">{userData?.isBlocked ? "Inactive" : "Active"}</Typography>
                    </Box>
                  </Grid>

                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>

        {/* <Card sx={{ mt: 4 }} className="cards">
          <Box className="custom_tabs">
            <Tabs
              value={value}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons="auto"
              aria-label="basic tabs example"
              className="custom_tabs_links"
            >
              <Tab label="Post For Sell" {...a11yProps(0)} />
              <Tab label="Post For Auction" {...a11yProps(1)} />
              <Tab label="Earnings" {...a11yProps(2)} />
            </Tabs>
            <CustomTabPanel value={value} index={0}>
              <UserPostForSell></UserPostForSell>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              <UserPostForAuction></UserPostForAuction>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
              <UserEarnings></UserEarnings>
            </CustomTabPanel>
          </Box>
        </Card> */}
      </div>
    </MainContainer>
  );
};

export default UserDetails;
