import {
  Box,
  Button,
  Container,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import * as Yup from "yup";
import { useFormik } from "formik";
import React from "react";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { usePostForgotPasswordMutation } from "../../services/auth";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import { CommonBody } from "../../types/General";
import { showError, showToast } from "../../constants/toast";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [ForgotPasswordMutation, { isLoading }] = usePostForgotPasswordMutation();
   

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .required("Email is required!")
        .matches(
          /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
          "Enter a valid email address"
        ),
    }),

    onSubmit: async () => {
      formik.setSubmitting(true);
      let data = {
        email: formik.values.email,
      };

      try {
        let encryptedBody = generateEncryptedKeyBody(data) as CommonBody;
        const response = await ForgotPasswordMutation(encryptedBody).unwrap();
        if (response?.statusCode === 200) {
          const data = {
            email: formik.values.email,
          };
          navigate("/verifyotp", { state: data });
          showToast(response?.message);
        }
      } catch (error: any) {
        showError(error?.data?.message || "");
        console.log(error);
      }
    },
  });

  return (
    <Box
      component="main"
      className="auth_module"
      sx={{
        alignItems: "center",
        display: "flex",
        flexGrow: 1,
      }}
    >
      <Container className="auth_cntnr" maxWidth="xs">
        <Box
          m={0}
          className="auth_logo"
          sx={{ display: "flex", justifyContent: "center" }}
        >
          <figure className="logo_cntnr">
            <img src="/static/images/logo.png" alt="" />
          </figure>
        </Box>
        <div className="auth_box">
          <Box sx={{ mb: 3, textAlign: "center", position: "relative" }}>
            <IconButton
              onClick={() => navigate("/")}
              sx={{ p: 0, position: "absolute", left: 0, color: "#1c6975" }}
            >
              <ArrowBackIcon />
            </IconButton>
            <Typography variant="h5" sx={{ color: "#1c6975" }}>
              Forgot Password
            </Typography>
          </Box>
          <form onSubmit={formik.handleSubmit}>
            <FormControl sx={{ width: "100%" }}>
              <Typography className="custom_label">Email Address</Typography>
              <TextField
                hiddenLabel
                placeholder="Email Address"
                fullWidth
                name="email"
                type="email"
                variant="outlined"
                id="email"
                onChange={(val) => {
                  if (val.target.value === " " || val.target.value === ".") {
                  } else {
                    formik.handleChange(val);
                  }
                }}
                onBlur={formik.handleBlur}
                value={formik.values.email}
                helperText={formik.touched.email && formik.errors.email}
              />
            </FormControl>
            <Box sx={{ pt: 3 }}>
              <Button
                className="btn btn_primary"
                color="primary"
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                // onClick={() => navigate("/verifyotp")}
              >
                Send
              </Button>
            </Box>
          </form>
        </div>
      </Container>
    </Box>
  );
};

export default ForgotPassword;
